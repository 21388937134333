import React, { useState, useEffect, memo } from "react";
import "./ChatBox.css";
import ChatWindow from "./chat-window/ChatWindow";
import moment from "moment";
import { users } from "../utils/data.js";

import mqtt from "mqtt/dist/mqtt";

const client = mqtt.connect("wss://broker.emqx.io:8084/mqtt");

const ChatBox = () => {
  const [allUsers, setAllUsers] = useState(users);

  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);

  const [currentTab, setcurrentTab] = useState("");

  const [userName, setUserName] = useState("");


  useEffect(() => {
    let uN = prompt("Please enter your name to continue")
    setUserName(uN)
    sessionStorage.setItem("UserName", uN);

    client.on("error", (err) => {
      console.log(err);
    });

    client.on("connect", () => {
      console.log("Connected to HiveMQ Cloud");
    });

    client.on("reconnect", () => {
      console.log("MQTT client is reconnecting...");
    });

    client.on("close", () => {
      console.log("MQTT client is disconnected.");
    });

  }, []);

  //Receiving msg
  client.on("message", (topic, msg) => {
    const jsonParse = JSON.parse(msg);
    const origin = jsonParse.originIpAddress;
    const message = jsonParse.message.toString();

    if (jsonParse.originIpAddress !== userName && topic === currentTab) {
      setMessages([
        ...messages,
        {
          message,
          originIpAddress: origin,
          dateTimeStamp: moment().format("LLLL"),
        },
      ]);
    }
  });

  //Sending msg
  const handleMessageSent = (message) => {
    client.publish(currentTab || "TOPIC", JSON.stringify(message));

    console.log(message, currentTab);
    setMessages([
      ...messages,
      {
        ...message,
        originIpAddress: message.originIpAddress,
        dateTimeStamp: moment().format("LLLL"),
      },
    ]);
  };



  const profileFromChild = async (currentP, index) => {
    setcurrentTab(currentP);
    await client.subscribe(currentP || "TOPIC");
    setMessages(users[index].messages);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="main-chat-box">
        <button
          className="chat-btn"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
          >
            <path
              d="M256,22.092c-138.983,0-256,95.395-256,218.074c0,68.111,36.742,131.331,99.275,172.501l-41,49.199
		c-10.61,12.731,1.49,31.898,17.712,27.361l127.252-35.623C360.614,481.645,512,380.357,512,240.167
		C512,117.51,395.009,22.092,256,22.092z"
            />
          </svg>
          {/* <span>My Messages</span> */}
        </button>
        <ChatWindow
          activeUser={userName}
          isOpen={isOpen}
          messages={messages}
          onClose={handleClose}
          onMessageSent={handleMessageSent}
          title="All Messages"
          allUsers={allUsers}
          profileFromChild={profileFromChild}
        />
      </div>
    </>
  );
};

export default memo(ChatBox);
