import "./App.css";
import ChatBox from "./components/ChatBox";

function App() {
  return (
    <div className="App">
      <div className="main-app">
     <ChatBox/>
      </div>
    </div>
  );
}

export default App;
