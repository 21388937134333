import img1 from "../assets/images/moon.png";
import img2 from "../assets/images/earth.png";
import img3 from "../assets/images/Robot-Pic.png";


export const users = [
  {
    username: "User 1",
    userimage: img1,
    messages: [
      {
        message: "User 1",
        dateTimeStamp: "Tuesday, March 10, 2023 3:36 PM",
        originIpAddress: "Anshul"
      },
    ],
  },
  {
    username: "User 2",
    userimage: img2,
    messages: [
      {
        message: "User 2",
        dateTimeStamp: "Monday, March 1, 2021 12:03 PM",
        originIpAddress: "Anshul"
      },
    ],
  },
  {
    username: "User 3",
    userimage: img3,
    messages: [
      {
        originIpAddress: "Anshul",
        message: "User 3",
        dateTimeStamp: "Tuesday, March 10, 2023 3:36 PM",
      },
      
    ],
  },
 
];
