import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createFocusTrap } from "focus-trap";
import ChatMessage from "../chat-msg/ChatMsg";
import "./chat-window.css";

const ChatWindow = ({
  isOpen,
  messages,
  onClose,
  onMessageSent,
  position,
  title,
  allUsers,
  profileFromChild,
  activeUser
}) => {

  const [userIndex,setUserIndex] = useState(0)

  const chatWindow = useRef();
  const chatWindowBody = useRef();
  const userInput = useRef();

  const [message, setValue] = useState("");
  const [focusTrap, setFocusTrap] = useState(null);
  // const [ipAddress, setIpAddress] = useState("Anshul");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    onMessageSent({ message, originIpAddress: activeUser });
    setValue("");
  };

  const setChatWindowScrollPosition = () => {
    const _chatWindowBody = chatWindowBody.current;
    _chatWindowBody.scrollTop = _chatWindowBody.scrollHeight;
  };

  const autExpandInput = () => {
    const _userInput = userInput.current;
    _userInput.style.height = "auto";
    _userInput.style.height = `${_userInput.scrollHeight}px`;
  };

  useEffect(() => {
    // fetch("https://ipinfo.io/json")
    //   .then((res) => res.json())
    //   .then(({ ip }) => setIpAddress(ip));

    setFocusTrap(
      createFocusTrap(chatWindow.current, {
        clickOutsideDeactivates: true,
        fallbackFocus: chatWindow.current,
      })
    );
  }, []);

  useEffect(() => {
    if (!focusTrap) return;

    if (isOpen) {
      focusTrap.activate();
    } else {
      focusTrap.deactivate();
    }
  }, [isOpen, focusTrap]);

  useEffect(() => {
    setChatWindowScrollPosition();
  }, [messages]);

  useEffect(() => {
    autExpandInput();
  }, [message]);

  // const currentPersonInChat = (e) => {
  //   profileFromChild(e.target.id);
  // };

  return (
    <div
      ref={chatWindow}
      className={classnames("chat-window", {
        "is-open": isOpen,
        [`chat-window--${position}`]: position,
      })}
    >
      <div className="chat-window__header relative items-center flex justify-between">
        <div className="other-users h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide">
          {allUsers &&
            allUsers.map((user, index) => {
              return (
                <div key={index}>
                  <div
                    className="user-1"
                    name={"val"}
                    onClick={(e) => {
                      profileFromChild(e.target.id, index);
                      setUserIndex(index)
                    }}
                  >
                    <div className="user-circle">
                      <img
                        src={user.userimage}
                        alt={user.username}
                        id={user.username}
                        className="user-main-icon"
                      />
                    </div>
                    <div className="user-name" id={user.username}>
                      {user.username}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <button className="chat-window__close-btn" onClick={() => onClose()}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
          >
            <path
              d="M493.268,0H18.732C8.387,0,0,8.387,0,18.732v474.537C0,503.613,8.387,512,18.732,512h474.537
		c10.345,0,18.732-8.387,18.732-18.732V18.732C512,8.387,503.613,0,493.268,0z M358.763,332.273c7.315,7.314,7.315,19.175,0,26.49
		s-19.175,7.315-26.49,0L256,282.49l-76.273,76.273c-7.315,7.315-19.175,7.315-26.49,0c-7.315-7.314-7.315-19.175,0-26.49
		L229.51,256l-76.273-76.273c-7.315-7.314-7.315-19.175,0-26.49c7.314-7.314,19.175-7.314,26.49,0L256,229.51l76.273-76.273
		c7.314-7.314,19.175-7.314,26.49,0c7.315,7.314,7.315,19.175,0,26.49L282.49,256L358.763,332.273z"
            />
          </svg>
        </button>
      </div>
      <div ref={chatWindowBody} className="chat-window__body">



      {/* {allUsers[userIndex].messages && allUsers[userIndex].messages.map(({ originIpAddress, ...props })=>{
         <ChatMessage
              key={Math.random()}
              isSameOrigin={originIpAddress === ipAddress}
              {...props}
            />
       
      })} */}
        {messages &&
          messages.map(({ originIpAddress, ...props }) => (
            <ChatMessage
              key={Math.random()}
              isSameOrigin={originIpAddress === activeUser}
              {...props}
            />
          ))}
      </div>
      <div className="chat-window__footer">
        <input
          ref={userInput}
          className="chat-window__input"
          rows="1"
          placeholder="Enter your message..."
          value={message}
          onChange={handleChange}
          onKeyPress={(e) => (e.key === "Enter" ? handleSubmit() : "")}
        />
        <button
          className="chat-window__send-btn"
          type="button"
          onClick={() => handleSubmit()}
          disabled={!message}
        >
          Send
        </button>
      </div>
    </div>
  );
};

ChatWindow.propTypes = {
  isOpen: PropTypes.bool,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      originIpAddress: PropTypes.string,
      message: PropTypes.string,
      dateTimeStamp: PropTypes.string,
    })
  ),
  onClose: PropTypes.func,
  onMessageSent: PropTypes.func,
  position: PropTypes.oneOf([
    "top-right",
    "top-left",
    "bottom-right",
    "bottom-left",
  ]),
  title: PropTypes.string,
};

ChatWindow.defaultProps = {
  isOpen: false,
  messages: [],
  onClose() {},
  onMessageSent() {},
  position: "bottom-right",
  title: "Chat",
};

export default ChatWindow;
